import { TEventLogRecord } from '@/helpers/api/eventLogs/types';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { IHeaderItem, TableSimple } from '@/packages/tableSimple/TableSimple';
import { AxiosError } from "axios";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLogMessage } from '@/components/logs/LogsTable/helpers';
import { useUserInfoModel } from '@/components/UserInfoModal/provider';
import { StyledUserNameWrapper } from '@/components/UserInfoModal/index.styled';
import { SortOrder } from '@/packages/nestedTable/NestedTable';
import { HeaderArgType } from '@/packages/stickyTable/StickyTable';

export interface ILogsTable {
    onRestore?: () => void;
  onRestoreError?: (error: AxiosError<{ message: string }>) => void;
  data: TEventLogRecord[];
  headers?: IHeaderItem<TEventLogRecord>[];
  setRequestParams?: React.Dispatch<any>;
}

export const LogsTable: React.FC<ILogsTable> = (props) => {
    const { headers, setRequestParams, ...restProps } = props;

    const { t } = useTranslation();
    const { dispatch } = useUserInfoModel();

    const defaultHeaders: IHeaderItem<TEventLogRecord>[] = [
        {
            Header: t('models.logsModel.date') || '',
            accessor: 'date',
            Cell: ({ row }) => new Date(row?.original?.date || '').toLocaleString(),
        },
        {
            Header: t('models.logsModel.account') || '',
            accessor: 'account',
        },
        {
            Header: t('models.logsModel.user') || '',
            accessor: 'user',

            Cell: ({ row }) => {
                return (
                    <StyledUserNameWrapper onClick={() => dispatch({ userId: row.original.user?.id ?? '' })}>
                        {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
                    </StyledUserNameWrapper>
                );
            },
        },
        {
            Header: t('models.logsModel.change') || '',
            accessor: 'action',
            Cell: ({ row }) => {
                return (
                    <Paragraph size={12} weight={300}>
                        {getLogMessage(
                            row.original.action,
                            row.original.entity,
                            row.original,
                        )}
                    </Paragraph>
                );
            },
        },
    ];

    const handleSort = (accessor: HeaderArgType, order: SortOrder) => {
        setRequestParams && setRequestParams((prev) => ({
            ...prev,
            orderBy: order ? order.toUpperCase() : null,
            sortBy: order ? accessor.id : null,
        }));
    };

    return (
        <TableSimple
            withOverflow
            autoOverflow
            {...restProps}
            headers={headers ?? defaultHeaders}
            headerParams={{
                color: 'white' as any,
            }}
            onSort={handleSort}
        />
    );
};
